// Login page with tailt wind for mobile and desktop

import React from 'react';

const Home = () => {
    return (
        <div className="flex flex-col justify-center items-center h-screen">
            <h1 className="text-4xl text-gray-800">Home</h1>
        </div>
    );
};

export default Home;