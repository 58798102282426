
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const navigate = useNavigate();
    return (
        <div className="min-h-screen bg-gray-950 flex items-center justify-center">
            <div className="max-w-md w-full p-4 space-y-8">
                <div>
                    <img className="mx-auto w-11/12" src="images/logo_white.png" alt="Logo" />
                </div>
                <form className="space-y-4">
                    <div>
                        <label htmlFor="email" className="block text-white text-left">Usuario</label>
                        <input
                            id="email"
                            name="email"
                            type="email"
                            required
                            className="mt-1 p-2 block w-full rounded-md bg-white border-gray-300"
                        />
                    </div>
                    <div>
                        <label htmlFor="password" className="block text-white text-left">Contraseña</label>
                        <input
                            id="password"
                            name="password"
                            type="password"
                            required
                            className="mt-1 p-2 block w-full rounded-md bg-white border-gray-300"
                        />
                    </div>
                    <div>
                        <button
                            onClick={() => navigate('/home')}
                            className="bg-blue-500 hover:bg-blue-700 text-white w-full py-2 rounded-md">
                            Ingresar
                        </button>
                    </div>
                </form>
            <div className="flex justify-center items-center h-16 bg-gray-950 text-white">
                <p>© 2023 - Todos los derechos reservados</p>
            </div>
            </div>
        </div>
    );
};

export default Login;
